"use client";

import { useState } from "react";
import { Ladder, LadderTeam } from "../types";
import { ArrowUpDown, ChevronDown, ChevronUp } from "lucide-react";
import { cn } from "@/common/utils";
import Image from "next/image";
import { TeamLogo } from "@/common/components/image/team-logo";

type SortColumn = "pts" | "pct" | "w" | "l" | "d" | "f" | "a" | "p" | null;
type SortDirection = "asc" | "desc";

interface LadderTableProps {
  ladder: Ladder;
  className?: string;
}

export default function LadderTable({ ladder, className }: LadderTableProps) {
  const [sortColumn, setSortColumn] = useState<SortColumn>("pts");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");

  const { finalsCutoff, doubleChanceCutoff } = ladder.competition;

  const handleSort = (column: SortColumn) => {
    if (column === sortColumn) {
      // Toggle direction if same column
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Set new column and default direction
      setSortColumn(column);
      setSortDirection(
        // Default to desc for most stats, asc for points against (lower is better)
        column === "a" ? "asc" : "desc"
      );
    }
  };

  // Sort the teams
  const sortedTeams = [...ladder.teams].sort((a, b) => {
    if (!sortColumn) return 0;

    // For percentage, use the sort_pct which is numeric
    const colA =
      sortColumn === "pct" ? parseFloat(a.sort_pct) : parseFloat(a[sortColumn]);
    const colB =
      sortColumn === "pct" ? parseFloat(b.sort_pct) : parseFloat(b[sortColumn]);

    // Apply sort direction
    return sortDirection === "asc" ? colA - colB : colB - colA;
  });

  // Create array of row elements
  const rowElements: React.ReactNode[] = sortedTeams.map((team, index) => {
    // Check if this row needs a special bottom border for finals cutoff
    const isFinalsCutoff = finalsCutoff !== null && finalsCutoff === index + 1;
    const isDoubleChanceCutoff =
      doubleChanceCutoff !== null && doubleChanceCutoff === index + 1;

    // Position style based on rank/position
    let positionStyle = "";
    if (index < 4) {
      positionStyle = "border-2 border-green-500 text-green-800 font-bold"; // Top 4
    } else if (index < 8) {
      positionStyle = "border border-blue-400 text-blue-700"; // 5-8 (Finals)
    } else {
      positionStyle = "border border-gray-300 text-gray-600"; // Rest
    }

    // Determine row border style
    let rowBorderStyle = "border-b border-gray-200";
    if (isFinalsCutoff) {
      rowBorderStyle = "border-b-2 border-blue-400";
    } else if (isDoubleChanceCutoff) {
      rowBorderStyle = "border-b-2 border-green-400";
    }

    // Add the team row
    return (
      <tr
        key={team.tid}
        className={cn(`text-xs ${rowBorderStyle} hover:bg-gray-50`, {
          "bg-blue-50": finalsCutoff ? index < finalsCutoff : false,
          "bg-green-50":
            (doubleChanceCutoff ? index < doubleChanceCutoff : false) &&
            (finalsCutoff ? index < finalsCutoff : false),
        })}
      >
        <td className="py-1 px-1 text-center w-7">
          <div
            className={cn(
              "rounded-full w-5 h-5 flex items-center justify-center mx-auto text-[10px]",
              positionStyle
            )}
          >
            {index + 1}
          </div>
        </td>
        <td className="py-1 px-0.5 w-16">
          <div className="flex items-center gap-1.5">
            <div className="flex-shrink-0 flex items-center justify-center">
              <TeamLogo teamAbbrev={team.ta} width={16} height={16} />
            </div>
            <span className="font-medium text-xs">{team.ta}</span>
          </div>
        </td>
        <td className="py-1 px-0.5 text-center">
          {team.p}
          {team.live && "*"}
        </td>
        <td className="py-1 px-0.5 text-center">{team.w}</td>
        <td className="py-1 px-0.5 text-center">{team.d}</td>
        <td className="py-1 px-0.5 text-center">{team.l}</td>
        <td className="py-1 px-0.5 text-center">{team.f}</td>
        <td className="py-1 px-0.5 text-center">{team.a}</td>
        <td className="py-1 px-0.5 text-center">
          {parseFloat(team.pct).toFixed(1)}
        </td>
        <td className="py-1 px-0.5 text-center font-medium">{team.pts}</td>
      </tr>
    );
  });

  return (
    <div className={cn("overflow-x-auto", className)}>
      <table className="w-full text-xs border-collapse">
        <thead>
          <tr className="bg-blue-50">
            <HeaderCell
              column={null}
              label="#"
              currentSort={sortColumn}
              currentDirection={sortDirection}
            />
            <HeaderCell
              column={null}
              label="Team"
              currentSort={sortColumn}
              currentDirection={sortDirection}
            />
            <HeaderCell
              column="p"
              label="P"
              currentSort={sortColumn}
              currentDirection={sortDirection}
              onSort={handleSort}
            />
            <HeaderCell
              column="w"
              label="W"
              currentSort={sortColumn}
              currentDirection={sortDirection}
              onSort={handleSort}
            />
            <HeaderCell
              column="d"
              label="D"
              currentSort={sortColumn}
              currentDirection={sortDirection}
              onSort={handleSort}
            />
            <HeaderCell
              column="l"
              label="L"
              currentSort={sortColumn}
              currentDirection={sortDirection}
              onSort={handleSort}
            />
            <HeaderCell
              column="f"
              label="F"
              currentSort={sortColumn}
              currentDirection={sortDirection}
              onSort={handleSort}
            />
            <HeaderCell
              column="a"
              label="A"
              currentSort={sortColumn}
              currentDirection={sortDirection}
              onSort={handleSort}
            />
            <HeaderCell
              column="pct"
              label="%"
              currentSort={sortColumn}
              currentDirection={sortDirection}
              onSort={handleSort}
            />
            <HeaderCell
              column="pts"
              label="Pt"
              currentSort={sortColumn}
              currentDirection={sortDirection}
              onSort={handleSort}
            />
          </tr>
        </thead>
        <tbody>{rowElements}</tbody>
      </table>
    </div>
  );
}

interface HeaderCellProps {
  column: SortColumn;
  label: string;
  currentSort?: SortColumn;
  currentDirection?: SortDirection;
  onSort?: (column: SortColumn) => void;
  align?: "left" | "center" | "right";
  isNumeric?: boolean;
}

function HeaderCell({
  column,
  label,
  currentSort,
  currentDirection,
  onSort,
  align = "center",
  isNumeric = false,
}: HeaderCellProps) {
  const isSorted = column === currentSort;
  const sortable = column !== null && onSort;
  const isTeamHeader = label === "Team";

  return (
    <th
      className={cn(
        "px-0.5 py-1 font-medium border-b border-blue-100 text-blue-700",
        isTeamHeader ? "text-left" : "text-center",
        sortable ? "cursor-pointer hover:bg-blue-100" : "cursor-default",
        isSorted && "text-blue-900 font-semibold"
      )}
      onClick={() => sortable && onSort?.(column)}
    >
      <div
        className={cn(
          "flex items-center gap-0.5",
          isTeamHeader ? "justify-start pl-1" : "justify-center"
        )}
      >
        {label}
        {sortable && (
          <>
            {isSorted ? (
              currentDirection === "asc" ? (
                <ChevronUp className="h-3 w-3" />
              ) : (
                <ChevronDown className="h-3 w-3" />
              )
            ) : (
              <ArrowUpDown className="h-2.5 w-2.5 opacity-50" />
            )}
          </>
        )}
      </div>
    </th>
  );
}
