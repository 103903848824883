import React from "react";
import { cn } from "@/common/utils/cn";

interface TipBoxProps {
  saved: boolean;
  unsaved: boolean;
  open: boolean;
  onTip: () => void;
  value: number;
  currentTippingPoints: number;
  score: number;
  correct: boolean | null;
  margin?: number | null;
  marginEnabled?: boolean;
  autoPick?: boolean;
  isLive?: boolean;
  tippingOpen?: boolean;
}

export function TipBox({
  saved,
  unsaved,
  open,
  onTip,
  value,
  currentTippingPoints,
  score,
  correct,
  margin,
  marginEnabled,
  autoPick,
  isLive,
  tippingOpen,
}: TipBoxProps) {
  // Style variations based on state
  const tipStyles = {
    saved: "bg-blue-500 text-white border-blue-600",
    unsaved: "bg-orange-500 text-white border-orange-600",
    correct: "bg-green-500 text-white border-green-600",
    incorrect: "bg-red-500 text-white border-red-600",
    unchecked: "bg-gray-300 text-gray-700 border-gray-400",
  };

  // Determine the style based on correctness and saved status
  let style = unsaved
    ? tipStyles.unsaved
    : saved
    ? correct === true
      ? tipStyles.correct
      : correct === false
      ? tipStyles.incorrect
      : tipStyles.saved
    : tipStyles.unchecked;

  // Determine if checked
  const checked = saved || unsaved;

  if (!open && !saved) return null;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (open) {
      try {
        onTip();
      } catch (error) {
        console.error("Error in tip handler:", error);
      }
    }
  };

  // Enhanced score display function with auto-pick support
  const getScoreDisplay = () => {
    // For unsaved tips (orange background), show the current match tipping points value
    if (unsaved) {
      // For unsaved tips, always show the current match tipping_points value
      return currentTippingPoints === 1
        ? "✓"
        : Math.round(currentTippingPoints * 100);
    }

    // For auto-picked tips, show star icon
    if (autoPick) {
      return "★";
    }

    // For incorrect tips
    if (correct === false) {
      // If score is 0, show a red cross
      if (score === 0) {
        return "✗";
      }
      // For other incorrect tips, show the score percentage
      return Math.round(score * 100);
    }

    // For correct tips
    if (correct === true) {
      const scorePercent = Math.round(score * 100);
      // If score is 100%, show a checkmark
      if (scorePercent === 100) {
        return "✓";
      }
      // Otherwise show the score percentage
      return scorePercent;
    }

    // For saved tips (blue background) where match is not complete (pre-match or live)
    if (saved) {
      // If the score is calculated (percentage between 0-1)
      if (score > 0) {
        const scorePercent = Math.round(score * 100);
        // If score is 100%, show a checkmark
        if (scorePercent === 100) {
          return "✓";
        }
        // Otherwise show the score percentage
        return scorePercent;
      }

      // For saved tips, show the saved historical value
      return value === 1 ? "✓" : Math.round(value * 100);
    }

    // For empty tip boxes, return an empty string
    return "";
  };

  return (
    <button
      type="button"
      className={cn(
        "flex items-center justify-center text-[8px] font-medium rounded-full border transition-colors",
        "w-4 h-4", // Fixed size regardless of margin
        style,
        open
          ? "cursor-pointer hover:opacity-80 active:opacity-70"
          : "cursor-default opacity-90"
      )}
      onClick={handleClick}
      disabled={!open}
      aria-label="Select tip"
    >
      {checked ? <span>{getScoreDisplay()}</span> : ""}
    </button>
  );
}
