"use client";

import React, { useCallback, useState, useEffect, useRef } from "react";
import { ResultsSummaryMatch, ResultsSummaryBye } from "../types";
import { Card, CardContent } from "@/common/components/ui/card";
import { Skeleton } from "@/common/components/ui/skeleton";
import { Badge } from "@/common/components/ui/badge";
import {
  Clock,
  Calendar,
  Save,
  X,
  Loader2,
  Check,
  AlertCircle,
} from "lucide-react";
import { Checkbox } from "@/common/components/ui/checkbox";
import { cn } from "@/common/utils/cn";
import { TeamLogo } from "@/common/components/image/team-logo";
import { Button } from "@/common/components/ui/button";
import { Input } from "@/common/components/ui/input";
import { toast } from "sonner";
import { usePathname, useSearchParams } from "next/navigation";
import Link from "next/link";
// Import the new types and hooks from the tipping service
import {
  SavedTip,
  Tip,
  TipError,
  SaveTipsResult,
  useTips,
  useSaveTips,
} from "@/features/tipping/services/tipping";
// Import auth context and actions
import { useAuth } from "@/features/auth/context/auth-context";
import { handleSignIn } from "@/features/auth/actions/auth-actions";
// Import our extracted components
import { MatchListItem } from "./MatchListItem";
import { formatFriendlyDate } from "../utils/formatters";

// Create a simple Popover component since we can't import the UI one
const Popover = ({
  children,
  open,
  onOpenChange,
}: {
  children: React.ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  return <div className="relative">{children}</div>;
};

const PopoverTrigger = ({
  asChild,
  children,
}: {
  asChild?: boolean;
  children: React.ReactNode;
}) => {
  return <>{children}</>;
};

const PopoverContent = ({
  children,
  className,
  side = "bottom",
  align = "center",
}: {
  children: React.ReactNode;
  className?: string;
  side?: "top" | "bottom" | "left" | "right";
  align?: "start" | "center" | "end";
}) => {
  const alignClass =
    align === "start"
      ? "left-0"
      : align === "end"
      ? "right-0"
      : "left-1/2 -translate-x-1/2";
  const sideClass = side === "bottom" ? "top-full mt-1" : "bottom-full mb-1";

  return (
    <div
      className={cn(
        "absolute z-50 bg-white border rounded-md shadow-md",
        sideClass,
        alignClass,
        className
      )}
    >
      {children}
    </div>
  );
};

// Types for tipping - Reuse exported types from tipping service
export type { SavedTip, Tip, TipError, SaveTipsResult };

export interface MatchListProps {
  matches: ResultsSummaryMatch[];
  byes: ResultsSummaryBye[];
  loading: boolean;
  savedTips?: SavedTip[];
  onTipChange?: (tip: Tip) => void;
  unsavedTips?: Tip[];
  tipErrors?: TipError[];
  saveTips?: (tips: Tip[]) => Promise<SaveTipsResult>;
}

export function MatchList({
  matches,
  byes,
  loading,
  savedTips = [],
  onTipChange,
  unsavedTips = [],
  tipErrors = [],
  saveTips,
}: MatchListProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [localUnsavedTips, setLocalUnsavedTips] = useState<Tip[]>(unsavedTips);

  // Initialize with props or an empty array
  const [localSavedTips, setLocalSavedTips] = useState<SavedTip[]>(savedTips);

  // Add local state for tip errors if not managed by parent
  const [localTipErrors, setLocalTipErrors] = useState<TipError[]>(tipErrors);

  // State to track if login message is dismissed
  const [isLoginMessageDismissed, setIsLoginMessageDismissed] = useState(() => {
    // Use lazy initialization to read from localStorage only on initial render
    if (typeof window !== "undefined") {
      return localStorage.getItem("tipsLoginMessageDismissed") === "true";
    }
    return false;
  });

  // Get the current pathname and search params for return URL
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // Get authentication state
  const { isAuthenticated } = useAuth();

  // Function to handle dismissing the login message
  const dismissLoginMessage = () => {
    setIsLoginMessageDismissed(true);
    localStorage.setItem("tipsLoginMessageDismissed", "true");
  };

  // Function to handle sign in with return path
  const handleSignInClick = () => {
    // Create the full current path including search params
    let currentPath = pathname;
    const searchString = searchParams.toString();
    if (searchString) {
      currentPath += `?${searchString}`;
    }

    // Call handleSignIn with the current path as returnTo
    handleSignIn(currentPath);
  };

  // Extract match IDs for the hooks
  const matchIds = matches.map((match) => match.id);

  // Use the React Query hook instead of manual fetching - only if authenticated
  const {
    data: fetchedTips,
    isLoading: tipsLoading,
    isError: tipLoadError,
  } = useTips(isAuthenticated ? matchIds : []);

  // Use the React Query mutation hook for saving tips
  const saveTipsMutation = useSaveTips();

  // Track whether we've processed tips
  const [tipsLoaded, setTipsLoaded] = useState(false);

  // Use a reference to track previous match IDs to detect changes
  const prevMatchIdsRef = useRef<string>("");

  // Simplified effect for match ID changes only
  useEffect(() => {
    const currentMatchIds = matchIds.join(",");

    // Only run if the match IDs have changed
    if (prevMatchIdsRef.current !== currentMatchIds) {
      prevMatchIdsRef.current = currentMatchIds;

      // Reset loaded state when matchIds change
      setTipsLoaded(false);

      // Clear local tips when matches change if we're not using prop-provided tips
      if (savedTips.length === 0) {
        setLocalSavedTips([]);
      }
    }
  }, [matchIds, savedTips.length]);

  // Effect to update saved tips when they change
  useEffect(() => {
    // If savedTips provided via props, use those
    if (savedTips && savedTips.length > 0) {
      setLocalSavedTips(savedTips);
      if (!tipsLoaded) {
        setTipsLoaded(true);
      }
      return;
    }

    // Otherwise use fetched tips
    if (fetchedTips) {
      // Process tips for correct typing
      const processedTips = fetchedTips.map((tip) => {
        if (tip.margin === null) {
          const { margin, ...rest } = tip;
          return { ...rest, margin: undefined };
        }
        return tip;
      });

      // Only update if different to prevent loops
      const currentTipsJson = JSON.stringify(processedTips);
      const localTipsJson = JSON.stringify(localSavedTips);

      if (currentTipsJson !== localTipsJson) {
        setLocalSavedTips(processedTips);
      }

      if (!tipsLoaded) {
        setTipsLoaded(true);
      }
    }
  }, [savedTips, fetchedTips, tipsLoaded, localSavedTips]);

  // Simplified tip handler
  const handleTipChange = (tip: Tip) => {
    // If user is not authenticated, show login message
    if (!isAuthenticated) {
      toast.error("You must be logged in to enter tips", {
        description:
          "Please sign in to save your tips and track your progress.",
        action: {
          label: "Sign In",
          onClick: handleSignInClick,
        },
      });
      return;
    }

    if (onTipChange) {
      // If parent provided a handler, use it
      onTipChange(tip);
    } else {
      // Otherwise, manage tips locally
      setLocalUnsavedTips((prev) => {
        const existingIndex = prev.findIndex((t) => t.matchId === tip.matchId);
        if (existingIndex >= 0) {
          if (tip.teamId === null) {
            // Remove tip if teamId is null (clearing)
            return prev.filter((t) => t.matchId !== tip.matchId);
          }
          // Update existing tip
          return prev.map((t) => (t.matchId === tip.matchId ? tip : t));
        }
        // Add new tip
        return [...prev, tip];
      });
    }
  };

  // Use local tips if no external tips provided
  const effectiveUnsavedTips = onTipChange ? unsavedTips : localUnsavedTips;
  const effectiveSavedTips = savedTips.length > 0 ? savedTips : localSavedTips;
  const hasUnsavedTips = effectiveUnsavedTips.length > 0;

  // Use local errors if no external errors provided
  const effectiveTipErrors = tipErrors.length > 0 ? tipErrors : localTipErrors;

  // Group matches by day - simple calculation, no need for useMemo
  const matchesByDay = {};
  matches.forEach((match) => {
    const matchDate = match.match_date || "Unknown";
    if (!matchesByDay[matchDate]) {
      matchesByDay[matchDate] = [];
    }
    matchesByDay[matchDate].push(match);
  });

  // Sort dates - simple calculation, no need for useMemo
  const sortedDates = Object.keys(matchesByDay).sort();

  // Handle saving tips
  const handleSaveTips = async () => {
    if (!isAuthenticated) {
      toast.error("You must be logged in to save tips", {
        description:
          "Please sign in to save your tips and track your progress.",
        action: {
          label: "Sign In",
          onClick: handleSignInClick,
        },
      });
      return;
    }

    if (effectiveUnsavedTips.length === 0) return;

    try {
      setIsSaving(true);

      // Use provided saveTips function or fallback to our React Query mutation
      if (saveTips) {
        const result = await saveTips(effectiveUnsavedTips);
        handleSaveTipsResult(result);
      } else {
        // Use the mutation from React Query
        const result = await saveTipsMutation.mutateAsync(effectiveUnsavedTips);
        handleSaveTipsResult(result);
      }
    } catch (error) {
      // Show generic error toast
      console.error("Error saving tips:", error);
      toast.error(
        "Error saving tips. An unexpected error occurred. Please try again."
      );
    } finally {
      setIsSaving(false);
    }
  };

  // Handle the result of saving tips
  const handleSaveTipsResult = (result: SaveTipsResult) => {
    if (result.tipErrors && result.tipErrors.length > 0) {
      // Show error toast if there are tip errors
      toast.error(
        `Some tips could not be saved. ${result.tipErrors.length} tips failed to save. Please check errors below.`
      );

      // Store tip errors to display them with the matches
      if (result.tipErrors) {
        // If parent component provided tipErrors handler, defer to it
        if (onTipChange) {
          // We don't manage errors directly in this case
        } else {
          // Store errors locally
          setLocalTipErrors(result.tipErrors);
        }
      }
    } else if (result.error) {
      // Handle authentication or other errors
      toast.error(result.error.message);
    } else {
      // Show success toast if no errors
      toast.success(
        `Tips saved successfully! ${effectiveUnsavedTips.length} tips have been saved.`
      );

      // Update local saved tips with the newly saved ones
      if (result.savedTips && result.savedTips.length > 0) {
        setLocalSavedTips((prev) => {
          // Remove any existing saved tips for these matches
          const filteredPrev = prev.filter(
            (p) => !result.savedTips?.some((s) => s.matchId === p.matchId)
          );
          // Add the newly saved tips
          return [...filteredPrev, ...(result.savedTips as SavedTip[])];
        });
      }

      // Clear local unsaved tips
      setLocalUnsavedTips([]);

      // Clear any existing errors
      setLocalTipErrors([]);
    }
  };

  if (loading) {
    return (
      <div className="p-4 space-y-2">
        <div className="flex items-center justify-center">
          <div className="animate-pulse text-sm text-gray-500 flex items-center gap-2">
            <Loader2 className="h-4 w-4 animate-spin text-red-600" />
            <span>Loading matches...</span>
          </div>
        </div>
        <div className="space-y-1.5 opacity-50">
          <Skeleton className="h-16 w-full" />
          <Skeleton className="h-16 w-full" />
          <Skeleton className="h-16 w-full" />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-0 w-full relative">
      {/* Show login message for non-authenticated users, unless dismissed */}
      {!isAuthenticated && !isLoginMessageDismissed && (
        <div className="bg-amber-50 p-3 mb-2 rounded flex items-start gap-2 relative">
          <AlertCircle className="h-5 w-5 text-amber-600 flex-shrink-0 mt-0.5" />
          <div className="flex-1">
            <h4 className="text-sm font-medium text-amber-800 pr-6">
              Sign in to enter tips
            </h4>
            <p className="text-xs text-amber-700 mt-0.5">
              You need to sign in to enter tips and track your progress in
              tipping competitions.
            </p>
            <div className="mt-2">
              <Button
                size="sm"
                variant="outline"
                className="bg-white border-amber-200 text-amber-800 hover:bg-amber-100"
                onClick={handleSignInClick}
              >
                Sign In
              </Button>
            </div>
          </div>
          <button
            onClick={dismissLoginMessage}
            className="absolute top-2 right-2 text-amber-600 hover:text-amber-800"
            aria-label="Dismiss"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
      )}

      {/* Show error message if tips failed to load - only show if authenticated */}
      {isAuthenticated && tipLoadError && (
        <div className="text-xs text-amber-600 bg-amber-50 p-2 rounded mb-2 flex items-center">
          <span className="mr-1">⚠️</span> Failed to load your saved tips. Your
          tips may not appear.
        </div>
      )}

      {matches.length === 0 && byes.length === 0 ? (
        <div className="text-center py-2 text-gray-500 text-sm">
          No matches found for the selected criteria.
        </div>
      ) : (
        <>
          {sortedDates.map((date) => (
            <div key={date} className="space-y-0">
              <div className="flex items-center gap-1 bg-footyinfo-blue px-2 py-0.5 rounded-sm text-white">
                <Calendar className="h-2 w-2" />
                <h3 className="text-[10px] font-medium">
                  {formatFriendlyDate(date)}
                </h3>
              </div>

              <div className="bg-white w-full overflow-hidden">
                {matchesByDay[date].map((match, index) => (
                  <React.Fragment key={match.id}>
                    <MatchListItem
                      match={match}
                      savedTip={effectiveSavedTips.find(
                        (tip) => tip.matchId === match.id
                      )}
                      unsavedTip={effectiveUnsavedTips.find(
                        (tip) => tip.matchId === match.id
                      )}
                      onTipChange={handleTipChange}
                      tipErrors={effectiveTipErrors.filter(
                        (error) => error.matchId === match.id
                      )}
                      isAuthenticated={isAuthenticated}
                    />
                    {index < matchesByDay[date].length - 1 && (
                      <div className="border-b border-gray-200"></div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          ))}

          {byes.length > 0 && (
            <div className="space-y-0">
              <div className="flex items-center gap-1 bg-footyinfo-blue px-2 py-0.5 rounded-sm text-white">
                <span className="text-[10px]">👋</span>
                <h3 className="text-[10px] font-medium">BYE</h3>
              </div>
              <div className="bg-white w-full overflow-hidden p-2">
                <div
                  className={`grid gap-2 ${
                    byes.length === 1
                      ? "grid-cols-1"
                      : byes.length === 2
                      ? "grid-cols-2"
                      : byes.length === 3
                      ? "grid-cols-3"
                      : "grid-cols-4"
                  }`}
                >
                  {byes.map((bye) => (
                    <div
                      key={bye.team_id}
                      className="flex items-center justify-center gap-1"
                    >
                      <div className="w-4 h-4 flex-shrink-0">
                        <TeamLogo
                          teamAbbrev={bye.team}
                          width={16}
                          height={16}
                        />
                      </div>
                      <span className="text-xs">{bye.team}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Save tips button - only show for authenticated users with unsaved tips */}
          {isAuthenticated && hasUnsavedTips && (
            <div className="mx-4 my-6 flex justify-center">
              <Button
                className="bg-footyinfo-blue hover:bg-footyinfo-blue/90 text-white flex items-center gap-2 px-8 py-3 rounded-full shadow-md min-w-[180px]"
                onClick={handleSaveTips}
                disabled={isSaving || saveTipsMutation.isPending}
              >
                {isSaving || saveTipsMutation.isPending ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span className="text-sm font-semibold">Saving...</span>
                  </>
                ) : (
                  <>
                    <Check className="h-4 w-4" />
                    <span className="text-sm font-semibold">Save Tips</span>
                  </>
                )}
              </Button>
            </div>
          )}
        </>
      )}

      <style jsx global>{`
        @keyframes livePulse {
          0% {
            background-color: rgba(220, 252, 231, 0.5);
            box-shadow: inset 0 0 0 1px rgba(22, 163, 74, 0.1);
          }
          50% {
            background-color: rgba(220, 252, 231, 0.9);
            box-shadow: inset 0 0 0 1px rgba(22, 163, 74, 0.3);
          }
          100% {
            background-color: rgba(220, 252, 231, 0.5);
            box-shadow: inset 0 0 0 1px rgba(22, 163, 74, 0.1);
          }
        }
        .live-match-row {
          animation: livePulse 3s infinite ease-in-out;
        }
      `}</style>
    </div>
  );
}
