"use client";

import { useState, useEffect } from "react";
import { useLadder } from "../services/ladder-service";
import LadderTable from "./LadderTable";
import CompetitionSelect from "./CompetitionSelect";
import { LoadingSpinner } from "@/common/components/LoadingSpinner";
import { Alert, AlertDescription } from "@/common/components/ui/alert";
import { AlertCircle } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/common/components/ui/card";

interface LadderDisplayProps {
  initialCompetitionTypeId?: number;
  initialSeasonId?: number;
  initialData?: any;
}

export default function LadderDisplay({
  initialCompetitionTypeId,
  initialSeasonId,
  initialData,
}: LadderDisplayProps) {
  // Track actual selected IDs separate from what we use for queries
  const [currentCompetitionId, setCurrentCompetitionId] = useState<
    number | null
  >(initialCompetitionTypeId || null);
  const [currentSeasonId, setCurrentSeasonId] = useState<number | null>(
    initialSeasonId || null
  );

  // Different state for what we send to the query, only updated when we're ready to fetch new data
  const [queryCompetitionId, setQueryCompetitionId] = useState<number | null>(
    initialCompetitionTypeId || null
  );
  const [querySeasonId, setQuerySeasonId] = useState<number | null>(
    initialSeasonId || null
  );

  // Fetch ladder data with React Query
  const {
    data: ladder,
    isLoading,
    error,
  } = useLadder(queryCompetitionId, querySeasonId);

  // Use initialData if available and we're not loading
  const displayData = ladder || initialData;
  const isFirstLoad = !ladder && !error;

  // Track when we're actively loading data
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Handle competition change
  const handleCompetitionChange = (id: number) => {
    if (id === currentCompetitionId) return;
    setCurrentCompetitionId(id);
    setIsTransitioning(true);

    // Short delay to ensure the UI updates first
    setTimeout(() => {
      setQueryCompetitionId(id);
    }, 0);
  };

  // Handle season change
  const handleSeasonChange = (id: number) => {
    if (id === currentSeasonId) return;
    setCurrentSeasonId(id);
    setIsTransitioning(true);

    // Short delay to ensure the UI updates first
    setTimeout(() => {
      setQuerySeasonId(id);
    }, 0);
  };

  // Reset the transitioning state when new data arrives
  useEffect(() => {
    if ((ladder || error) && isTransitioning) {
      setIsTransitioning(false);
    }
  }, [ladder, error, isTransitioning]);

  // Determine if we should show loading state
  const showLoading = isLoading || isTransitioning;

  return (
    <Card className="bg-white shadow-sm w-full overflow-hidden">
      <CardHeader className="pb-0 px-0 pt-0">
        <CardTitle className="text-base font-bold bg-blue-700 text-white p-2 rounded-t-none">
          Ladder
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        {displayData && (
          <CompetitionSelect
            ladder={displayData}
            onCompetitionChange={handleCompetitionChange}
            onSeasonChange={handleSeasonChange}
            isLoading={showLoading}
            currentCompetitionId={currentCompetitionId}
            currentSeasonId={currentSeasonId}
          />
        )}

        {showLoading && (
          <div className="p-6 flex justify-center">
            <LoadingSpinner size="md" />
          </div>
        )}

        {error && !showLoading && (
          <Alert variant="destructive" className="m-2 text-xs">
            <AlertCircle className="h-3 w-3" />
            <AlertDescription className="text-xs">
              Error loading ladder data. Please try again.
            </AlertDescription>
          </Alert>
        )}

        {displayData && !showLoading && !isFirstLoad && (
          <LadderTable ladder={displayData} className="text-xs" />
        )}
      </CardContent>
    </Card>
  );
}
