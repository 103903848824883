import { useQuery } from "@tanstack/react-query";
import { useApi } from "@/common/api";
import { Ladder, LadderParams } from "../types";

// Helper function to convert params to query string
function getQueryString(params: Record<string, any>): string {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((item) =>
          queryParams.append(`${key}[]`, item.toString())
        );
      } else {
        queryParams.append(key, value.toString());
      }
    }
  });

  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

// Base service functions
function createLadderService(api: ReturnType<typeof useApi>) {
  return {
    fetchLadder: async (
      competitionTypeId?: number | null,
      seasonId?: number | null
    ): Promise<Ladder> => {
      const params: LadderParams = {};
      if (competitionTypeId) {
        params.competitionTypeId = competitionTypeId;
      }
      if (seasonId) {
        params.seasonId = seasonId;
      }
      const queryString = getQueryString(params);
      const json = await api.get<Ladder>(`/ladder${queryString}`, {
        authenticated: false,
      });
      return json;
    },
  };
}

// Hook to use the service
export function useLadderService() {
  const api = useApi();
  return createLadderService(api);
}

// React Query hooks for data fetching
export function useLadder(
  competitionTypeId?: number | null,
  seasonId?: number | null
) {
  const ladderService = useLadderService();

  return useQuery({
    queryKey: ["ladder", competitionTypeId, seasonId],
    queryFn: () => ladderService.fetchLadder(competitionTypeId, seasonId),
    staleTime: 60000, // 1 minute
  });
}
