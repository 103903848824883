"use client";

import React, { useState, useEffect } from "react";
import {
  ResultsSummarySelection,
  RoundResultsSelection,
  TeamResultsSelection,
} from "../types";
import {
  useLeagues,
  useSeasons,
  useCompetitions,
  useRounds,
  useTeams,
  useResultsSummary,
  RoundsParams,
  TeamsParams,
} from "../services/results-service";
import { RoundHeader } from "./RoundHeader";
import { MatchList } from "./MatchList";
import { Skeleton } from "@/common/components/ui/skeleton";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/common/components/ui/card";

// Define a type for the parameters
type ResultsSummaryParams =
  | { round_id: number }
  | { team_id: number; competition_ids: number[] }
  | null;

export function ResultsSummary() {
  // State for selections
  const [selection, setSelection] = useState<ResultsSummarySelection>({
    view: "ROUND",
  });

  // Fetch leagues
  const { data: leaguesData, isLoading: leaguesLoading } = useLeagues();

  // Initialize with default league when leagues are loaded
  useEffect(() => {
    if (leaguesData?.leagues && leaguesData.leagues.length > 0) {
      const defaultLeague =
        leaguesData.leagues.find((l) => l.default) || leaguesData.leagues[0];
      setSelection((prev) => ({ ...prev, leagueId: defaultLeague.id }));
    }
  }, [leaguesData]);

  // Fetch seasons based on selected league
  const { data: seasonsData, isLoading: seasonsLoading } = useSeasons(
    selection.leagueId
  );

  // Initialize with default/current season when seasons are loaded
  useEffect(() => {
    if (seasonsData?.seasons && seasonsData.seasons.length > 0) {
      const currentSeason =
        seasonsData.seasons.find((s) => s.current) || seasonsData.seasons[0];
      setSelection((prev) => ({ ...prev, seasonId: currentSeason.id }));
    }
  }, [seasonsData]);

  // Fetch competitions based on selected league and season
  const { data: competitionsData, isLoading: competitionsLoading } =
    useCompetitions(selection.leagueId, selection.seasonId);

  // Initialize with default competition types
  useEffect(() => {
    if (
      competitionsData?.competitions &&
      competitionsData.competitions.length > 0
    ) {
      const defaultCompetitions = competitionsData.competitions
        .filter((c) => c.default)
        .map((c) => c.id);

      setSelection((prev) => ({
        ...prev,
        competitionTypeIds:
          defaultCompetitions.length > 0
            ? defaultCompetitions
            : [competitionsData.competitions[0].id],
      }));
    }
  }, [competitionsData]);

  // Prepare params for rounds/teams based on selection
  const roundsParams: RoundsParams | null = selection.competitionTypeIds
    ? { competition_ids: selection.competitionTypeIds }
    : null;

  const teamsParams: TeamsParams | null = selection.competitionTypeIds
    ? { competition_ids: selection.competitionTypeIds }
    : null;

  // Fetch rounds and teams based on competitions
  const { data: roundsData, isLoading: roundsLoading } =
    useRounds(roundsParams);
  const { data: teamsData, isLoading: teamsLoading } = useTeams(teamsParams);

  // Initialize with current round or team when data is loaded
  useEffect(() => {
    if (
      selection.view === "ROUND" &&
      roundsData?.rounds &&
      roundsData.rounds.length > 0
    ) {
      const roundSelection = selection as RoundResultsSelection;
      if (!roundSelection.roundId) {
        const currentRound =
          roundsData.rounds.find((r) => r.current) || roundsData.rounds[0];
        setSelection(
          (prev) =>
            ({
              ...prev,
              roundId: currentRound.id,
            } as RoundResultsSelection)
        );
      }
    } else if (
      selection.view === "TEAM" &&
      teamsData?.teams &&
      teamsData.teams.length > 0
    ) {
      const teamSelection = selection as TeamResultsSelection;
      if (!teamSelection.teamId && teamsData.teams.length > 0) {
        setSelection(
          (prev) =>
            ({
              ...prev,
              teamId: teamsData.teams[0].id,
            } as TeamResultsSelection)
        );
      }
    }
  }, [roundsData, teamsData, selection]);

  // Prepare params for results summary - simple inline calculation
  let resultsSummaryParams: ResultsSummaryParams = null;
  if (selection.view === "ROUND") {
    const roundSelection = selection as RoundResultsSelection;
    if (roundSelection.roundId) {
      resultsSummaryParams = { round_id: roundSelection.roundId };
    }
  } else if (selection.view === "TEAM") {
    const teamSelection = selection as TeamResultsSelection;
    if (teamSelection.teamId && teamSelection.competitionTypeIds) {
      resultsSummaryParams = {
        team_id: teamSelection.teamId,
        competition_ids: teamSelection.competitionTypeIds,
      };
    }
  }

  // Fetch results summary
  const { data: resultsSummary, isLoading: resultsSummaryLoading } =
    useResultsSummary(resultsSummaryParams);

  // Get selected objects - simple lookups
  const selectedLeague =
    leaguesData?.leagues?.find((l) => l.id === selection.leagueId) || null;
  const selectedSeason =
    seasonsData?.seasons?.find((s) => s.id === selection.seasonId) || null;

  const selectedRound =
    selection.view === "ROUND"
      ? roundsData?.rounds?.find(
          (r) => r.id === (selection as RoundResultsSelection).roundId
        ) || null
      : null;

  const selectedTeam =
    selection.view === "TEAM"
      ? teamsData?.teams?.find(
          (t) => t.id === (selection as TeamResultsSelection).teamId
        ) || null
      : null;

  // Toggle between round and team views
  function toggleView() {
    if (selection.view === "ROUND") {
      setSelection({
        ...selection,
        view: "TEAM",
        teamId: teamsData?.teams?.[0]?.id,
      } as TeamResultsSelection);
    } else {
      setSelection({
        ...selection,
        view: "ROUND",
        roundId: roundsData?.rounds?.[0]?.id,
      } as RoundResultsSelection);
    }
  }

  // Set selected item (round or team)
  function setSelectedId(id: number) {
    if (selection.view === "ROUND") {
      setSelection(
        (prev) =>
          ({
            ...prev,
            roundId: id,
          } as RoundResultsSelection)
      );
    } else {
      setSelection(
        (prev) =>
          ({
            ...prev,
            teamId: id,
          } as TeamResultsSelection)
      );
    }
  }

  // Handle league selection
  function handleSelectLeague(leagueId: string) {
    setSelection((prev) => ({
      ...prev,
      leagueId,
      seasonId: undefined,
      competitionTypeIds: undefined,
      roundId: undefined,
    }));
  }

  // Handle season selection
  function handleSelectSeason(seasonId: number) {
    setSelection((prev) => ({
      ...prev,
      seasonId,
      competitionTypeIds: undefined,
      roundId: undefined,
    }));
  }

  // Handle competition selection
  function handleSelectCompetitions(competitionIds: number[]) {
    setSelection((prev) => ({
      ...prev,
      competitionTypeIds: competitionIds,
      roundId: undefined,
    }));
  }

  // Check if we have the essential data needed to render header and content
  const hasInitialData =
    !!leaguesData?.leagues &&
    !!seasonsData?.seasons &&
    !!competitionsData?.competitions &&
    (selection.view === "ROUND" ? !!roundsData?.rounds : !!teamsData?.teams);

  // For initial loading only - when we have no data yet
  const isInitialLoading =
    !hasInitialData &&
    (leaguesLoading ||
      seasonsLoading ||
      competitionsLoading ||
      roundsLoading ||
      teamsLoading);

  // Content loading (just to show match list loading)
  const isContentLoading = resultsSummaryLoading || !resultsSummary;

  return (
    <Card className="w-full shadow-sm overflow-hidden">
      <CardHeader className="pb-0 px-0 pt-0">
        <CardTitle className="text-base font-bold bg-red-600 text-white p-2 rounded-t-none">
          Scores and Tips
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        {isInitialLoading ? (
          // Only show skeleton on initial loading
          <div className="space-y-2 p-2">
            <Skeleton className="h-8 w-full" />
            <Skeleton className="h-32 w-full" />
          </div>
        ) : (
          <>
            {/* Header */}
            <RoundHeader
              round={selectedRound}
              team={selectedTeam}
              season={selectedSeason}
              league={selectedLeague}
              competitions={competitionsData?.competitions || null}
              rounds={roundsData?.rounds || null}
              teams={teamsData?.teams || null}
              selection={selection}
              toggleView={toggleView}
              setSelectedId={setSelectedId}
              onSelectLeague={handleSelectLeague}
              onSelectSeason={handleSelectSeason}
              onSelectCompetitions={handleSelectCompetitions}
              leagues={leaguesData?.leagues || null}
              seasons={seasonsData?.seasons || null}
            />

            {/* Match list */}
            <MatchList
              matches={resultsSummary?.matches || []}
              byes={resultsSummary?.byes || []}
              loading={isContentLoading}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}
