"use client";

import { Ladder } from "../types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/common/components/ui/select";
import { cn } from "@/common/utils";

interface CompetitionSelectProps {
  ladder: Ladder;
  onCompetitionChange: (id: number) => void;
  onSeasonChange: (id: number) => void;
  isLoading?: boolean;
  currentCompetitionId: number | null;
  currentSeasonId: number | null;
}

export default function CompetitionSelect({
  ladder,
  onCompetitionChange,
  onSeasonChange,
  isLoading = false,
  currentCompetitionId,
  currentSeasonId,
}: CompetitionSelectProps) {
  if (!ladder) return null;

  const { competitionTypes, seasons } = ladder;

  // Find the current competition and season objects based on ID, not the 'selected' flag
  const currentCompetition =
    competitionTypes.find((c) => c.id === currentCompetitionId) ||
    competitionTypes.find((c) => c.selected) ||
    competitionTypes[0];

  const currentSeason =
    seasons.find((s) => s.id === currentSeasonId) ||
    seasons.find((s) => s.selected) ||
    seasons[0];

  // Group seasons by decade for better organization
  const decadeGroups = seasons
    .sort((a, b) => parseInt(b.name) - parseInt(a.name)) // Sort seasons first
    .reduce((groups, season) => {
      const year = parseInt(season.name);
      if (isNaN(year)) return groups;

      const decade = Math.floor(year / 10) * 10;
      const existing = groups.find((g) => g.decade === decade);
      if (existing) {
        existing.seasons.push(season);
      } else {
        groups.push({
          decade,
          seasons: [season],
        });
      }
      return groups;
    }, [] as { decade: number; seasons: typeof seasons }[])
    .sort((a, b) => b.decade - a.decade); // Make sure decades are sorted

  return (
    <div className="flex flex-col sm:flex-row w-full bg-blue-700 mb-0 overflow-hidden">
      <div className="flex-1 border-b sm:border-b-0 sm:border-r border-blue-600">
        <Select
          value={currentCompetition?.id.toString()}
          onValueChange={(value) => onCompetitionChange(parseInt(value))}
          disabled={isLoading}
        >
          <SelectTrigger
            className={cn(
              "w-full bg-transparent border-0 text-white focus:ring-0 focus:ring-offset-0 h-8 text-xs",
              isLoading && "opacity-70"
            )}
          >
            <SelectValue
              placeholder="Select competition"
              className="text-white"
            >
              <span className="text-white">
                {currentCompetition?.name.replace(" Premiership Season", "") ||
                  "Select competition"}
              </span>
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="bg-white max-h-60 overflow-y-auto text-xs min-w-[180px]">
            {competitionTypes.map((comp) => (
              <SelectItem
                key={`comp-${comp.id}`}
                value={comp.id.toString()}
                className="text-xs py-1 pr-10 flex items-center"
              >
                <span className="truncate">
                  {comp.name.replace(" Premiership Season", "")}
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex-1">
        <Select
          value={currentSeason?.id.toString()}
          onValueChange={(value) => onSeasonChange(parseInt(value))}
          disabled={isLoading}
        >
          <SelectTrigger
            className={cn(
              "w-full bg-transparent border-0 text-white focus:ring-0 focus:ring-offset-0 h-8 text-xs",
              isLoading && "opacity-70"
            )}
          >
            <SelectValue placeholder="Select season" className="text-white">
              <span className="text-white">
                {currentSeason?.name || "Select season"}
              </span>
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="bg-white max-h-60 overflow-y-auto text-xs min-w-[120px]">
            {decadeGroups.map((group) => (
              <div key={`decade-${group.decade}`} className="py-0.5">
                <div className="text-[10px] font-semibold text-gray-500 px-2 py-0.5">
                  {group.decade}s
                </div>
                {group.seasons.map((season) => (
                  <SelectItem
                    key={`season-${season.id}`}
                    value={season.id.toString()}
                    className="text-xs py-1 pr-10 flex items-center"
                  >
                    <span className="truncate">{season.name}</span>
                  </SelectItem>
                ))}
              </div>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
