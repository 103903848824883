"use client";

import React, { useState, useRef, useEffect, memo } from "react";
import {
  ResultsSummarySelection,
  ResultsSummaryLeague,
  ResultsSummarySeason,
  ResultsSummaryRound,
  ResultsSummaryCompetition,
  ResultsSummaryTeam,
} from "../types";
import { Button } from "@/common/components/ui/button";
import { ChevronDown, ChevronLeft, ChevronRight, Check } from "lucide-react";
import { Checkbox } from "@/common/components/ui/checkbox";
import { cn } from "@/common/utils/cn";
import { Skeleton } from "@/common/components/ui/skeleton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
  DropdownMenuSeparator,
  DropdownMenuLabel,
} from "@/common/components/ui/dropdown-menu";

export interface RoundHeaderProps {
  round?: ResultsSummaryRound | null;
  team?: ResultsSummaryTeam | null;
  season: ResultsSummarySeason | null;
  league: ResultsSummaryLeague | null;
  competitions: ResultsSummaryCompetition[] | null;
  rounds?: ResultsSummaryRound[] | null;
  teams?: ResultsSummaryTeam[] | null;
  selection: ResultsSummarySelection;
  toggleView: () => void;
  setSelectedId: (id: number) => void;
  // Add handlers for the dropdowns
  onSelectLeague?: (leagueId: string) => void;
  onSelectSeason?: (seasonId: number) => void;
  onSelectCompetitions?: (competitionIds: number[]) => void;
  // Add data for dropdowns
  leagues?: ResultsSummaryLeague[] | null;
  seasons?: ResultsSummarySeason[] | null;
  // Add loading state
  isLoading?: boolean;
}

export function RoundHeaderComponent({
  round,
  team,
  season,
  league,
  competitions,
  rounds,
  teams,
  selection,
  toggleView,
  setSelectedId,
  onSelectLeague,
  onSelectSeason,
  onSelectCompetitions,
  leagues,
  seasons,
  isLoading = false,
}: RoundHeaderProps) {
  // Title based on selection view (round or team)
  const title = selection.view === "ROUND" ? round?.name : team?.name;

  // Tracked selected competitions
  const [selectedCompetitions, setSelectedCompetitions] = useState<number[]>(
    selection.competitionTypeIds || []
  );

  // Update selectedCompetitions when selection changes
  useEffect(() => {
    if (selection.competitionTypeIds) {
      setSelectedCompetitions(selection.competitionTypeIds);
    }
  }, [selection.competitionTypeIds]);

  // Add ref for the chips container
  const chipsContainerRef = useRef<HTMLDivElement>(null);

  // Add state to track if scrolling is needed
  const [showScrollIndicators, setShowScrollIndicators] = useState(false);

  // Check if scrolling is needed when rounds/teams change
  useEffect(() => {
    if (chipsContainerRef.current) {
      const container = chipsContainerRef.current;
      setShowScrollIndicators(container.scrollWidth > container.clientWidth);
    }
  }, [rounds, teams, selection.view]);

  // Get current index of selected round/team
  const selectedIndex = React.useMemo(() => {
    if (selection.view === "ROUND" && rounds && round) {
      return rounds.findIndex((r) => r.id === round.id);
    }

    if (selection.view === "TEAM" && teams && team) {
      return teams.findIndex((t) => t.id === team.id);
    }

    return -1;
  }, [round, rounds, team, teams, selection.view]);

  // Navigation functions
  const goToPrevious = () => {
    const items = selection.view === "ROUND" ? rounds : teams;
    if (!items || selectedIndex <= 0) return;

    setSelectedId(items[selectedIndex - 1].id);
  };

  const goToNext = () => {
    const items = selection.view === "ROUND" ? rounds : teams;
    if (!items || selectedIndex === -1 || selectedIndex >= items.length - 1)
      return;

    setSelectedId(items[selectedIndex + 1].id);
  };

  // Handle competitions
  const handleToggleCompetition = (competitionId: number) => {
    const newSelected = selectedCompetitions.includes(competitionId)
      ? selectedCompetitions.filter((id) => id !== competitionId)
      : [...selectedCompetitions, competitionId];

    setSelectedCompetitions(newSelected);
    if (onSelectCompetitions) {
      onSelectCompetitions(newSelected);
    }
  };

  // Helper function to extract just the competition name without league and year
  const getCompetitionDisplayText = () => {
    // Check if competitions exist
    if (!competitions || competitions.length === 0) {
      return "Select Competition";
    }

    // Type guard for competitionTypeIds
    const competitionTypeIds = selection.competitionTypeIds;
    if (!competitionTypeIds || competitionTypeIds.length === 0) {
      return "Select Competition";
    }

    // For multiple selected competitions, show "All competitions"
    if (competitionTypeIds.length > 1) {
      // Check if all competitions are selected
      if (competitions.length === competitionTypeIds.length) {
        return "All competitions";
      }
      return `${competitionTypeIds.length} Competitions`;
    }

    // For single competition, remove league name and year
    const comp = competitions.find((c) => c.id === competitionTypeIds[0]);
    if (comp) {
      return comp.name
        .replace(league?.name || "", "")
        .replace(/\d{4}$/, "")
        .trim();
    }

    return "Select Competition";
  };

  // Function to handle wheel event for horizontal scrolling
  const handleMouseWheel = (e: React.WheelEvent) => {
    if (chipsContainerRef.current) {
      e.preventDefault();
      chipsContainerRef.current.scrollLeft += e.deltaY;
    }
  };

  // Function to scroll to selected chip
  const scrollToChip = (id: number) => {
    setTimeout(() => {
      const selectedChip = document.getElementById(`chip-${id}`);
      if (selectedChip && chipsContainerRef.current) {
        // Calculate offset that ensures the chip is fully visible
        // We need to account for the fixed label + divider width (approximately 70px)
        chipsContainerRef.current.scrollTo({
          left: selectedChip.offsetLeft - 80,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  // Add effect to scroll to the selected chip when round/team changes or on initial render
  useEffect(() => {
    if (selection.view === "ROUND" && round?.id) {
      scrollToChip(round.id);
    } else if (selection.view === "TEAM" && team?.id) {
      scrollToChip(team.id);
    }
  }, [round?.id, team?.id, selection.view]);

  // Update select ID to also scroll
  const handleSelectId = (id: number) => {
    // Use a transition style to prevent flickering
    if (chipsContainerRef.current) {
      // Apply a slight transition to reduce visual jarring
      chipsContainerRef.current.style.opacity = "0.8";
      setTimeout(() => {
        if (chipsContainerRef.current) {
          chipsContainerRef.current.style.opacity = "1";
        }
      }, 150);
    }

    setSelectedId(id);
    scrollToChip(id);
  };

  // Simplified mouse dragging for horizontal scroll
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (chipsContainerRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - chipsContainerRef.current.offsetLeft);
      setScrollLeft(chipsContainerRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !chipsContainerRef.current) return;

    // Use passive: false to prevent conflicts with navigation
    e.preventDefault();

    const x = e.pageX - chipsContainerRef.current.offsetLeft;
    // Reduce multiplier to make scrolling less sensitive
    const walk = (x - startX) * 1;
    chipsContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  // Render skeleton UI for loading state
  const renderSkeletonUI = () => {
    return (
      <div className="flex flex-col mb-1 animate-pulse">
        {/* Header skeleton */}
        <div className="flex items-center justify-between bg-red-600 text-white py-1 px-2 relative">
          {/* League skeleton */}
          <div className="flex items-center">
            <Skeleton className="h-5 w-16 bg-red-500/50" />
          </div>

          {/* Competition skeleton */}
          <div className="flex-1 text-center mx-4">
            <Skeleton className="h-4 w-32 mx-auto bg-red-500/50" />
          </div>

          {/* Season skeleton */}
          <div className="flex items-center">
            <Skeleton className="h-4 w-14 bg-red-500/50" />
          </div>
        </div>

        {/* Round/Team navigation skeleton */}
        <div className="flex items-center justify-between py-1 px-2 border-b bg-gray-50">
          <Skeleton className="h-6 w-6 rounded-full bg-gray-200" />
          <Skeleton className="h-4 w-24 bg-gray-200" />
          <Skeleton className="h-6 w-6 rounded-full bg-gray-200" />
        </div>

        {/* Round/Team chips skeleton */}
        <div className="relative py-2 overflow-hidden">
          <div className="flex px-2 space-x-2">
            {/* Label skeleton - now sticky */}
            <Skeleton className="h-5 w-12 rounded-md bg-gray-200 flex-shrink-0 sticky left-0 z-10" />

            {/* Divider skeleton - now sticky */}
            <div className="h-5 border-r border-gray-300 mx-1 flex-shrink-0 sticky left-[34px] z-10 bg-gray-50"></div>

            {Array.from({ length: 7 }).map((_, i) => (
              <Skeleton
                key={i}
                className="h-8 w-16 rounded-full bg-gray-200 flex-shrink-0"
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  // Memoize the header part (red bar with league/competition/season) since it doesn't change when switching rounds
  const headerBar = React.useMemo(
    () => (
      <div className="flex items-center justify-between bg-red-600 text-white py-1 px-2 relative">
        {/* Left - League */}
        <div className="flex items-center relative">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="link"
                size="sm"
                className="flex justify-start items-center p-0.5 h-auto text-sm font-medium text-white hover:text-white/90 hover:bg-red-700 rounded-sm focus:outline-none focus-visible:ring-1 focus-visible:ring-white/50 focus-visible:ring-offset-1 focus-visible:ring-offset-red-700"
              >
                {league?.name || "Select League"}
                <ChevronDown className="ml-1 h-3 w-3" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="start"
              className="max-h-[300px] overflow-y-auto w-56"
            >
              <DropdownMenuLabel className="text-gray-500 text-xs">
                Select League
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              {leagues && leagues.length > 0 ? (
                leagues.map((l) => (
                  <DropdownMenuItem
                    key={l.id}
                    className={cn(
                      l.id === selection.leagueId
                        ? "text-red-600 font-medium bg-gray-50"
                        : "text-gray-800"
                    )}
                    onClick={() => {
                      if (onSelectLeague) {
                        onSelectLeague(l.id);
                      }
                    }}
                  >
                    {l.name}
                  </DropdownMenuItem>
                ))
              ) : (
                <DropdownMenuItem disabled>No leagues found</DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Center - Competition */}
        <div className="flex-1 flex items-center justify-center relative">
          {competitions && competitions.length > 1 ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="link"
                  size="sm"
                  className="flex items-center justify-center mx-auto p-0.5 h-auto text-xs text-white hover:text-white hover:bg-red-700 rounded-sm focus:outline-none focus-visible:ring-1 focus-visible:ring-white/50 focus-visible:ring-offset-1 focus-visible:ring-offset-red-700"
                >
                  {getCompetitionDisplayText()}
                  <ChevronDown className="ml-1 h-3 w-3" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="center"
                className="max-h-[300px] overflow-y-auto w-64"
              >
                <DropdownMenuLabel className="text-gray-500 text-xs">
                  Select Competition
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                {competitions && competitions.length > 0 ? (
                  <>
                    {competitions.map((c) => {
                      // Process the competition name once to avoid repetition
                      const competitionName = c.name
                        .replace(league?.name || "", "")
                        .replace(/\d{4}$/, "")
                        .trim();

                      // Check if this comp is the selected one (if only one selected)
                      const isSelected =
                        selection.competitionTypeIds?.length === 1 &&
                        selection.competitionTypeIds[0] === c.id;

                      return (
                        <DropdownMenuItem
                          key={c.id}
                          className={cn(
                            isSelected
                              ? "text-red-600 font-medium bg-gray-50"
                              : "text-gray-800"
                          )}
                          onClick={() => {
                            if (onSelectCompetitions) {
                              onSelectCompetitions([c.id]);
                            }
                          }}
                        >
                          {competitionName}
                        </DropdownMenuItem>
                      );
                    })}

                    <DropdownMenuSeparator />

                    {/* All competitions option */}
                    <DropdownMenuItem
                      className={cn(
                        selection.competitionTypeIds &&
                          selection.competitionTypeIds.length > 1
                          ? "text-red-600 font-medium bg-gray-50"
                          : "text-gray-800"
                      )}
                      onClick={() => {
                        if (onSelectCompetitions && competitions) {
                          onSelectCompetitions(competitions.map((c) => c.id));
                        }
                      }}
                    >
                      All competitions
                    </DropdownMenuItem>
                  </>
                ) : (
                  <DropdownMenuItem disabled>
                    No competitions found
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          ) : competitions && competitions.length === 1 ? (
            <span className="text-xs text-white/90 text-center">
              {competitions[0].name
                .replace(league?.name || "", "")
                .replace(/\d{4}$/, "")
                .trim()}
            </span>
          ) : (
            <span className="text-xs text-white/90 text-center">
              Competition
            </span>
          )}
        </div>

        {/* Right - Season */}
        <div className="flex items-center relative">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="link"
                size="sm"
                className="flex items-center p-0.5 h-auto text-xs text-white hover:text-white hover:bg-red-700 rounded-sm focus:outline-none focus-visible:ring-1 focus-visible:ring-white/50 focus-visible:ring-offset-1 focus-visible:ring-offset-red-700"
              >
                {season?.name || "Season"}
                <ChevronDown className="ml-1 h-3 w-3" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="max-h-[300px] overflow-y-auto w-56"
            >
              <DropdownMenuLabel className="text-gray-500 text-xs">
                Select Season
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              {seasons && seasons.length > 0 ? (
                seasons.map((s) => (
                  <DropdownMenuItem
                    key={s.id}
                    className={cn(
                      s.id === selection.seasonId
                        ? "text-red-600 font-medium bg-gray-50"
                        : "text-gray-800"
                    )}
                    onClick={() => {
                      if (onSelectSeason) {
                        onSelectSeason(s.id);
                      }
                    }}
                  >
                    {s.name}
                  </DropdownMenuItem>
                ))
              ) : (
                <DropdownMenuItem disabled>No seasons found</DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    ),
    [
      league?.name,
      season?.name,
      competitions,
      getCompetitionDisplayText,
      selection.leagueId,
      selection.seasonId,
      selection.competitionTypeIds,
      leagues,
      seasons,
      onSelectLeague,
      onSelectSeason,
      onSelectCompetitions,
    ]
  );

  // Memoize the round/team navigation bar (doesn't need to rerender when just switching rounds)
  const navigationBar = React.useMemo(
    () => (
      <div className="flex items-center justify-between py-1 px-2 border-b bg-gray-50">
        <Button
          variant="ghost"
          size="sm"
          disabled={selectedIndex <= 0}
          onClick={goToPrevious}
          className="p-0 h-6 w-6"
        >
          <ChevronLeft className="h-3.5 w-3.5" />
        </Button>

        <div className="text-center font-medium truncate text-xs max-w-[70%]">
          {title ||
            (selection.view === "ROUND" ? "Select Round" : "Select Team")}
        </div>

        <Button
          variant="ghost"
          size="sm"
          disabled={
            !rounds ||
            selectedIndex === -1 ||
            selectedIndex >= (rounds?.length || 0) - 1
          }
          onClick={goToNext}
          className="p-0 h-6 w-6"
        >
          <ChevronRight className="h-3.5 w-3.5" />
        </Button>
      </div>
    ),
    [
      title,
      selectedIndex,
      selection.view,
      rounds?.length,
      goToPrevious,
      goToNext,
    ]
  );

  // If loading, show skeleton UI
  if (isLoading) {
    return renderSkeletonUI();
  }

  return (
    <div className="flex flex-col mb-1">
      {/* Reorganized header with league/competition/season */}
      {headerBar}

      {/* Round/Team Navigation */}
      {navigationBar}

      {/* Scrollable container */}
      <div className="relative bg-gray-50">
        {/* Round/Team layout with fixed label and scrollable content */}
        <div className="flex">
          {/* Fixed Label for Round/Team that toggles view when clicked - outside scrollable area */}
          <div
            className="flex items-center justify-center bg-gray-200 hover:bg-gray-300 rounded-md px-2 h-5 my-0.5 ml-1 text-[10px] font-bold text-gray-700 flex-shrink-0 cursor-pointer"
            onClick={toggleView}
            title={`Switch to ${
              selection.view === "ROUND" ? "TEAM" : "ROUND"
            } view`}
          >
            {selection.view}
          </div>

          {/* Divider - outside scrollable area */}
          <div className="h-5 border-r border-gray-300 mx-1 my-0.5 flex-shrink-0"></div>

          {/* Scrollable chips container - now separate from the label */}
          <div
            ref={chipsContainerRef}
            className={`flex overflow-x-auto py-0.5 gap-1 no-scrollbar flex-grow ${
              isDragging ? "cursor-grabbing" : "cursor-grab"
            }`}
            onWheel={handleMouseWheel}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            style={{
              scrollBehavior: isDragging ? "auto" : "smooth", // Disable smooth scroll during drag
              userSelect: "none", // Prevent text selection during drag
              transition: "opacity 150ms ease-in-out", // Smooth opacity transitions
            }}
          >
            <style jsx>{`
              .no-scrollbar::-webkit-scrollbar {
                display: none;
              }
              .no-scrollbar {
                -ms-overflow-style: none;
                scrollbar-width: none;
              }
            `}</style>

            {selection.view === "ROUND" &&
              rounds &&
              rounds.map((r) => (
                <MemoizedRoundChip
                  key={r.id}
                  id={`chip-${r.id}`}
                  round={r}
                  selected={r.id === round?.id}
                  onClick={() => handleSelectId(r.id)}
                />
              ))}

            {selection.view === "TEAM" &&
              teams &&
              teams.map((t) => (
                <MemoizedTeamChip
                  key={t.id}
                  id={`chip-${t.id}`}
                  team={t}
                  selected={t.id === team?.id}
                  onClick={() => handleSelectId(t.id)}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// Round chip component - Add id prop
function RoundChip({
  round,
  selected,
  onClick,
  id,
}: {
  round: ResultsSummaryRound;
  selected: boolean;
  onClick: () => void;
  id?: string;
}) {
  return (
    <Button
      id={id}
      variant={selected ? "default" : "outline"}
      size="sm"
      className={`
        rounded-full 
        aspect-square 
        w-auto 
        min-w-5 
        h-5 
        p-0 
        text-[10px] 
        flex 
        items-center 
        justify-center
        ${round.current && !selected ? "border-red-400 text-red-600" : ""} 
        ${selected ? "bg-red-600 hover:bg-red-700" : ""}
      `}
      onClick={onClick}
    >
      <span className="px-1.5">{round.short_name || round.name}</span>
    </Button>
  );
}

// Memoized version
const MemoizedRoundChip = React.memo(RoundChip);

// Team chip component - Add id prop
function TeamChip({
  team,
  selected,
  onClick,
  id,
}: {
  team: ResultsSummaryTeam;
  selected: boolean;
  onClick: () => void;
  id?: string;
}) {
  return (
    <Button
      id={id}
      variant={selected ? "default" : "outline"}
      size="sm"
      className={`rounded-full px-1.5 py-0 h-5 text-[10px] ${
        selected ? "bg-red-600 hover:bg-red-700" : ""
      }`}
      onClick={onClick}
    >
      {team.abbrev || team.name}
    </Button>
  );
}

// Memoized version
const MemoizedTeamChip = React.memo(TeamChip);

// Create a memoized version of RoundHeader with custom comparison
export const RoundHeader = memo(
  RoundHeaderComponent,
  (prevProps, nextProps) => {
    // Only re-render if something important changed

    // Check if the view mode changed (ROUND/TEAM)
    if (prevProps.selection.view !== nextProps.selection.view) {
      return false; // Different view, re-render
    }

    // Handle ROUND view
    if (nextProps.selection.view === "ROUND") {
      // Check if the selected round ID changed
      const prevRoundId = prevProps.round?.id;
      const nextRoundId = nextProps.round?.id;

      if (prevRoundId !== nextRoundId) {
        return false; // Different round, re-render
      }

      // Check if the list of rounds changed (length)
      if (prevProps.rounds?.length !== nextProps.rounds?.length) {
        return false; // Different rounds list, re-render
      }
    }

    // Handle TEAM view
    if (nextProps.selection.view === "TEAM") {
      // Check if the selected team ID changed
      const prevTeamId = prevProps.team?.id;
      const nextTeamId = nextProps.team?.id;

      if (prevTeamId !== nextTeamId) {
        return false; // Different team, re-render
      }

      // Check if the list of teams changed
      if (prevProps.teams?.length !== nextProps.teams?.length) {
        return false; // Different teams list, re-render
      }
    }

    // Check if leagues, competitions, or seasons changed
    if (
      prevProps.league?.id !== nextProps.league?.id ||
      prevProps.season?.id !== nextProps.season?.id ||
      JSON.stringify(prevProps.selection.competitionTypeIds) !==
        JSON.stringify(nextProps.selection.competitionTypeIds)
    ) {
      return false; // Different selection criteria, re-render
    }

    // No important changes, prevent re-render
    return true;
  }
);
