import React, { useCallback, useState, useEffect } from "react";
import Link from "next/link";
import { ResultsSummaryMatch } from "../types";
import { SavedTip, Tip, TipError } from "@/features/tipping/services/tipping";
import { TeamLogo } from "@/common/components/image/team-logo";
import { Input } from "@/common/components/ui/input";
import { AlertCircle } from "lucide-react";
import { TipBox } from "./TipBox";
import { cn } from "@/common/utils/cn";
import { formatQuarterDuration, formatTime } from "../utils/formatters";

interface MatchListItemProps {
  match: ResultsSummaryMatch;
  savedTip?: SavedTip;
  unsavedTip?: Tip;
  onTipChange?: (tip: Tip) => void;
  tipErrors?: TipError[];
  isAuthenticated?: boolean;
}

export function MatchListItem({
  match,
  savedTip,
  unsavedTip,
  onTipChange,
  tipErrors = [],
  isAuthenticated,
}: MatchListItemProps) {
  // Improved margin initialization to properly handle null/undefined values
  const [marginInput, setMarginInput] = useState<string>(() => {
    // First check unsaved tip margin
    if (unsavedTip?.margin !== undefined && unsavedTip?.margin !== null) {
      return unsavedTip.margin.toString();
    }
    // Then check saved tip margin
    if (savedTip?.margin !== undefined && savedTip?.margin !== null) {
      return savedTip.margin.toString();
    }
    // Default to empty string if no margin exists
    return "";
  });

  // Add a useEffect to update margin input when savedTip or unsavedTip changes
  useEffect(() => {
    // Store current margin values to detect actual changes
    const unsavedMargin = unsavedTip?.margin;
    const savedMargin = savedTip?.margin;

    if (unsavedMargin !== undefined && unsavedMargin !== null) {
      setMarginInput(unsavedMargin.toString());
    } else if (savedMargin !== undefined && savedMargin !== null) {
      setMarginInput(savedMargin.toString());
    } else if (unsavedTip === undefined && savedTip === undefined) {
      // Reset to empty if no tips exist
      setMarginInput("");
    }
    // Depend on the objects themselves, not their nested properties
  }, [savedTip, unsavedTip]);

  // Add debounce timer for auto-saving margin
  const [marginDebounceTimer, setMarginDebounceTimer] =
    useState<NodeJS.Timeout | null>(null);

  const homeWinner =
    match.complete &&
    (match.home_score !== undefined && match.away_score !== undefined
      ? match.home_score > match.away_score
      : false);

  const awayWinner =
    match.complete &&
    (match.home_score !== undefined && match.away_score !== undefined
      ? match.away_score > match.home_score
      : false);

  const isLive = match.status === "L";

  // Determine match status display
  const getMatchStatusDisplay = () => {
    if (match.complete) {
      // For completed games, we'll return null as we'll show the scores directly in a different component
      return null;
    } else if (isLive) {
      // For live matches, show quarter information
      // Use match.status or middle property as fallback for status_text
      let quarterInfo = match.middle || match.status || "";

      // If we don't have quarter info from the API, use a default "LIVE" indicator
      if (!quarterInfo) {
        return "LIVE";
      }

      // If we have quarter_duration, add it to the display
      if (match.quarter_duration !== undefined && !match.quarter_complete) {
        const quarterDuration = formatQuarterDuration(match.quarter_duration);
        if (quarterDuration) {
          return `${quarterInfo} ${quarterDuration}`;
        }
      }

      return quarterInfo;
    } else if (match.status === "U" || !match.status) {
      // Return time for upcoming matches
      return formatTime(match.match_time); // Upcoming match - show time without seconds
    } else {
      // For other statuses, use middle or status property
      return match.middle || match.status;
    }
  };

  // Determine result indicator
  const getResultIndicator = () => {
    if (
      (!match.complete && !isLive) ||
      match.home_score === undefined ||
      match.away_score === undefined
    ) {
      return null;
    }
    return homeWinner ? "df" : awayWinner ? "lt" : "drew";
  };

  // Auto-save margin function
  const autoSaveMargin = useCallback(
    (value: string) => {
      if (!onTipChange || !unsavedTip?.teamId) return;

      // Parse margin properly ensuring it's a valid number or null
      const newMarginValue = value.trim() !== "" ? parseInt(value, 10) : null;

      // Check if we're reverting to the original margin of the saved tip
      if (
        savedTip &&
        unsavedTip.teamId === savedTip.teamId &&
        (savedTip.margin === newMarginValue ||
          (savedTip.margin === undefined && newMarginValue === null))
      ) {
        // Reset to saved state by passing null teamId
        onTipChange({
          matchId: match.id,
          teamId: null,
          margin: null,
          expectedValue: match.tipping_points || 0,
          saved: false,
        });
        return;
      }

      // Only update if the value has changed
      if (newMarginValue !== unsavedTip.margin) {
        const updatedTip: Tip = {
          ...unsavedTip,
          margin: newMarginValue,
        };
        onTipChange(updatedTip);
      }
    },
    [unsavedTip, savedTip, onTipChange, match]
  );

  // Clear tip
  const handleClearTip = useCallback(() => {
    if (!onTipChange) return;

    onTipChange({
      matchId: match.id,
      teamId: null,
      margin: null,
      expectedValue: match.tipping_points || 0,
      saved: false,
    });
    setMarginInput("");

    // Clear any existing timer
    if (marginDebounceTimer) {
      clearTimeout(marginDebounceTimer);
    }
  }, [match.id, onTipChange, match.tipping_points, marginDebounceTimer]);

  // Handle tip team with better logic for saved tips
  const handleTipTeam = useCallback(
    (teamId: number) => {
      // If no onTipChange handler or user not authenticated, don't continue
      if (!onTipChange || !isAuthenticated) {
        return;
      }

      // If tipping is not open and there's no existing tip, don't continue
      if (!match.tipping_open && !savedTip && !unsavedTip) {
        return;
      }

      // If we already have an unsaved tip for this team, clear it
      if (unsavedTip?.teamId === teamId) {
        handleClearTip();
        return;
      }

      // If we're selecting the same team as the original saved tip, reset to saved state
      if (savedTip?.teamId === teamId && onTipChange) {
        // Clear the unsaved tip to revert to saved state
        onTipChange({
          matchId: match.id,
          teamId: null,
          margin: null,
          expectedValue: match.tipping_points || 0,
          saved: false,
        });

        // Reset margin input to the saved margin value
        if (
          match.margin_tipping &&
          savedTip?.margin !== undefined &&
          savedTip?.margin !== null
        ) {
          setMarginInput(savedTip.margin.toString());
        }

        return;
      }

      // Parse margin properly ensuring it's a valid number or null
      const marginValue =
        match.margin_tipping && marginInput.trim() !== ""
          ? parseInt(marginInput, 10)
          : null;

      // Create a new unsaved tip - preserve the current margin input
      const newTip: Tip = {
        matchId: match.id,
        teamId,
        margin: marginValue,
        expectedValue: match.tipping_points || 0,
        saved: false,
      };

      onTipChange(newTip);
    },
    [
      match,
      savedTip,
      unsavedTip,
      onTipChange,
      marginInput,
      handleClearTip,
      isAuthenticated,
    ]
  );

  // Improved margin change handler with auto-save
  const handleMarginChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      // Only allow numbers and limit to reasonable margins (0-200)
      if (value === "" || (/^\d+$/.test(value) && parseInt(value, 10) <= 200)) {
        setMarginInput(value);

        // Clear any existing timer
        if (marginDebounceTimer) {
          clearTimeout(marginDebounceTimer);
        }

        // Set a debounce timer to auto-save after typing stops
        if (unsavedTip?.teamId || savedTip?.teamId) {
          // If there's an unsaved tip already, update its margin
          if (unsavedTip?.teamId) {
            setMarginDebounceTimer(
              setTimeout(() => {
                // Check if we're reverting to the original margin value of the saved tip
                const newMarginValue =
                  value.trim() !== "" ? parseInt(value, 10) : null;

                // If teamId matches saved tip and margin matches saved tip, revert to saved state
                if (
                  savedTip &&
                  unsavedTip.teamId === savedTip.teamId &&
                  (savedTip.margin === newMarginValue ||
                    (savedTip.margin === undefined && newMarginValue === null))
                ) {
                  // Reset to saved state by passing null teamId
                  if (onTipChange) {
                    onTipChange({
                      matchId: match.id,
                      teamId: null,
                      margin: null,
                      expectedValue: match.tipping_points || 0,
                      saved: false,
                    });
                  }
                } else {
                  // Otherwise update the unsaved tip normally
                  autoSaveMargin(value);
                }
              }, 800)
            );
          }
          // If there's a saved tip but no unsaved tip yet, create a new unsaved tip based on the saved one
          else if (savedTip?.teamId && onTipChange) {
            setMarginDebounceTimer(
              setTimeout(() => {
                const newMarginValue =
                  value.trim() !== "" ? parseInt(value, 10) : null;
                const savedMarginValue = savedTip.margin;

                // If the new margin matches the saved margin, don't create an unsaved tip
                if (
                  savedMarginValue === newMarginValue ||
                  (savedMarginValue === undefined && newMarginValue === null)
                ) {
                  // No need to do anything - it matches the saved state
                  return;
                }

                // Otherwise create a new unsaved tip
                const newTip: Tip = {
                  matchId: match.id,
                  teamId: savedTip.teamId,
                  margin: newMarginValue,
                  expectedValue: savedTip.value || match.tipping_points || 0,
                  saved: false,
                };
                onTipChange(newTip);
              }, 800)
            );
          }
        }
      }
    },
    [
      unsavedTip,
      savedTip,
      onTipChange,
      match,
      marginDebounceTimer,
      autoSaveMargin,
    ]
  );

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (marginDebounceTimer) {
        clearTimeout(marginDebounceTimer);
      }
    };
  }, [marginDebounceTimer]);

  // Get tip value safely depending on whether it's a saved or unsaved tip
  const getTipValue = () => {
    // Always prioritize saved or unsaved tip values when available
    if (savedTip) return savedTip.value;
    if (unsavedTip) return unsavedTip.expectedValue;

    // If no tip exists, use current match tipping points
    return match.tipping_points || 1;
  };

  const resultIndicator = getResultIndicator();

  // Look for margin-specific errors
  const marginError = tipErrors.find((error) =>
    error.message.toLowerCase().includes("margin")
  );

  // Determine if this match has an active tip (either saved or unsaved)
  const hasActiveTip = Boolean(unsavedTip?.teamId || savedTip?.teamId);

  return (
    <div className="bg-white relative">
      {/* Main match row with all elements in a single row */}
      <Link
        href={`/match/${match.slug || match.id}`}
        className="block cursor-pointer"
        prefetch={false}
      >
        <div
          className={`p-1 flex flex-row items-center w-full hover:bg-slate-50 min-h-[36px] ${
            isLive ? "live-match-row" : ""
          }`}
        >
          {/* Home team logo + name - fixed width */}
          <div className="flex items-center pl-1 w-[25%]">
            <div className="w-4 h-4 flex-shrink-0 mr-1">
              <TeamLogo teamAbbrev={match.home_team} width={16} height={16} />
            </div>
            <span
              className={`text-xs truncate ${homeWinner ? "font-bold" : ""} ${
                isLive ? "font-medium" : ""
              }`}
            >
              {match.home_team}
            </span>
          </div>

          {/* Home team tip box - fixed width */}
          <div className="flex justify-center items-center w-[10%]">
            {(match.tipping_open || savedTip) && (
              <TipBox
                saved={
                  !!savedTip &&
                  savedTip.teamId === match.home_team_id &&
                  !unsavedTip
                }
                unsaved={
                  !!unsavedTip && unsavedTip.teamId === match.home_team_id
                }
                open={!!(match.tipping_open && isAuthenticated)}
                onTip={() => handleTipTeam(match.home_team_id)}
                value={getTipValue()}
                currentTippingPoints={match.tipping_points || 1}
                score={
                  savedTip?.teamId === match.home_team_id
                    ? savedTip?.score || 0
                    : 0
                }
                correct={
                  savedTip?.teamId === match.home_team_id
                    ? savedTip?.correct
                    : match.complete
                    ? false
                    : null
                }
                margin={null}
                marginEnabled={match.margin_tipping}
                autoPick={
                  savedTip?.teamId === match.home_team_id
                    ? savedTip?.autoPick
                    : false
                }
                isLive={match.status === "L"}
                tippingOpen={match.tipping_open}
              />
            )}
          </div>

          {/* Middle/Status section - fixed width and consistent height */}
          <div className="flex flex-col items-center justify-center w-[30%] min-h-[24px]">
            {/* For upcoming or live matches, show status */}
            {(!match.complete || isLive) && (
              <div className="flex flex-col items-center w-full">
                {/* For live matches, always show scores with quarter indicator between them */}
                {isLive &&
                  match.home_score !== undefined &&
                  match.away_score !== undefined && (
                    <div className="flex items-center justify-between w-full">
                      <div className="w-[40%] flex justify-end pr-2">
                        <span className="text-xs font-medium">
                          {match.home_score}
                        </span>
                      </div>

                      {/* Quarter indicator as a column with quarter name and duration */}
                      <div className="flex flex-col items-center w-[20%]">
                        <span className="text-[8px] bg-green-700 text-white px-1 py-0 rounded-sm font-medium">
                          {match.middle || match.status || "LIVE"}
                        </span>
                        {match.quarter_duration !== undefined &&
                          !match.quarter_complete && (
                            <span className="text-[7px] text-green-700 font-medium mt-0.5">
                              {formatQuarterDuration(match.quarter_duration)}
                            </span>
                          )}
                      </div>

                      <div className="w-[40%] flex justify-start pl-2">
                        <span className="text-xs font-medium">
                          {match.away_score}
                        </span>
                      </div>
                    </div>
                  )}

                {/* Show match status for non-live matches or live matches without scores */}
                {(!isLive ||
                  (isLive &&
                    (match.home_score === undefined ||
                      match.away_score === undefined))) && (
                  <div className="w-full text-center">
                    <div
                      className={`text-[9px] font-medium ${
                        isLive ? "text-green-700" : "text-gray-700"
                      }`}
                    >
                      {getMatchStatusDisplay()}
                    </div>

                    {/* Show ground name for upcoming matches */}
                    {(match.status === "U" || !match.status) &&
                      match.ground_name && (
                        <div className="text-[7px] text-gray-500 mt-0.5">
                          {match.ground_name}
                        </div>
                      )}
                  </div>
                )}
              </div>
            )}

            {/* Scores with result indicator for completed games only */}
            {match.complete &&
              !isLive &&
              match.home_score !== undefined &&
              match.away_score !== undefined && (
                <div className="flex items-center justify-between w-full">
                  <div className="w-[40%] flex justify-end pr-2">
                    <span
                      className={`text-xs ${homeWinner ? "font-bold" : ""}`}
                    >
                      {match.home_score}
                    </span>
                  </div>

                  <div className="w-[20%] flex justify-center">
                    <span className="text-[9px] text-gray-500 px-1">
                      {resultIndicator}
                    </span>
                  </div>

                  <div className="w-[40%] flex justify-start pl-2">
                    <span
                      className={`text-xs ${awayWinner ? "font-bold" : ""}`}
                    >
                      {match.away_score}
                    </span>
                  </div>
                </div>
              )}
          </div>

          {/* Away team tip box - fixed width */}
          <div className="flex justify-center items-center w-[10%]">
            {(match.tipping_open || savedTip) && (
              <TipBox
                saved={
                  !!savedTip &&
                  savedTip.teamId === match.away_team_id &&
                  !unsavedTip
                }
                unsaved={
                  !!unsavedTip && unsavedTip.teamId === match.away_team_id
                }
                open={!!(match.tipping_open && isAuthenticated)}
                onTip={() => handleTipTeam(match.away_team_id)}
                value={getTipValue()}
                currentTippingPoints={match.tipping_points || 1}
                score={
                  savedTip?.teamId === match.away_team_id
                    ? savedTip?.score || 0
                    : 0
                }
                correct={
                  savedTip?.teamId === match.away_team_id
                    ? savedTip?.correct
                    : match.complete
                    ? false
                    : null
                }
                margin={null}
                marginEnabled={match.margin_tipping}
                autoPick={
                  savedTip?.teamId === match.away_team_id
                    ? savedTip?.autoPick
                    : false
                }
                isLive={match.status === "L"}
                tippingOpen={match.tipping_open}
              />
            )}
          </div>

          {/* Away team name + logo - fixed width */}
          <div className="flex items-center justify-end pr-1 w-[25%]">
            <span
              className={`text-xs truncate ${awayWinner ? "font-bold" : ""} ${
                isLive ? "font-medium" : ""
              } mr-1`}
            >
              {match.away_team}
            </span>
            <div className="w-4 h-4 flex-shrink-0">
              <TeamLogo teamAbbrev={match.away_team} width={16} height={16} />
            </div>
          </div>
        </div>
      </Link>

      {/* Margin input row - below the main row - only show if authenticated */}
      {match.margin_tipping &&
        hasActiveTip &&
        match.tipping_open &&
        isAuthenticated && (
          <div className="px-2 pb-1 pt-0.5 border-t border-gray-100 bg-gray-50/80">
            <div className="flex items-center justify-center gap-2">
              <div className="text-[10px] text-gray-500">Margin:</div>
              <Input
                type="text"
                placeholder="0"
                className={cn(
                  "h-5 text-xs w-12 py-0 px-1.5 text-center",
                  marginError ? "border-red-500 focus-visible:ring-red-500" : ""
                )}
                value={marginInput}
                onChange={handleMarginChange}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>
            {marginError && (
              <div className="text-[9px] text-red-500 mt-0.5 text-center">
                {marginError.message}
              </div>
            )}
          </div>
        )}

      {/* Display all tip errors below the match */}
      {tipErrors && tipErrors.length > 0 && (
        <div className="bg-red-50 px-3 py-1.5 border-t border-red-100">
          {tipErrors.map((error, index) => (
            <div
              key={`${match.id}-error-${index}`}
              className="flex items-start gap-1.5 mb-1 last:mb-0"
            >
              <AlertCircle className="h-3 w-3 text-red-500 mt-0.5 flex-shrink-0" />
              <p className="text-xs text-red-600 leading-tight">
                {error.message}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
