/**
 * Format date to friendly display: SAT FEB 22 2025, 5:20pm
 */
export const formatFriendlyDate = (
  dateString: string,
  timeString?: string
): string => {
  if (!dateString) return "";

  try {
    const date = new Date(dateString);
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    let formattedDate = `${dayOfWeek} ${month} ${day} ${year}`;

    if (timeString) {
      // Format time to 12-hour format with am/pm
      const timeParts = timeString.split(":");
      let hours = parseInt(timeParts[0], 10);
      const minutes = timeParts[1];
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert 0 to 12
      formattedDate += `, ${hours}:${minutes}${ampm}`;
    }

    return formattedDate;
  } catch (e) {
    return dateString; // Return original if parsing fails
  }
};

/**
 * Format quarter duration from seconds to MM:SS format
 */
export const formatQuarterDuration = (seconds: number | undefined): string => {
  if (!seconds && seconds !== 0) return "";
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
};

/**
 * Format time to remove :00 seconds
 */
export const formatTime = (time: string): string => {
  if (!time) return "";
  return time.replace(/:00$/, "");
};
