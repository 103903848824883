"use client";

import React, { createContext, useContext, useEffect, useState } from "react";

// Types for our auth context
interface AuthContextType {
  isAuthenticated: boolean;
  email: string | null;
  screenName: string | null;
  isLoading: boolean;
  refreshAuth: () => Promise<void>;
  error: string | null;
}

// Create the context with a default value
const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  email: null,
  screenName: null,
  isLoading: true,
  refreshAuth: async () => {},
  error: null,
});

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [screenName, setScreenName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Function to fetch auth state
  const refreshAuth = async () => {
    try {
      setIsLoading(true);

      // Fetch the current auth state from cookies via an API endpoint
      const response = await fetch("/api/auth/session");

      if (!response.ok) {
        throw new Error("Failed to fetch authentication state");
      }

      const data = await response.json();

      setIsAuthenticated(data.isAuthenticated || false);
      setEmail(data.email || null);
      setScreenName(data.screenName || null);
      setError(null);
    } catch (err) {
      console.error("Auth refresh error:", err);
      setError(err instanceof Error ? err.message : "Authentication error");
    } finally {
      setIsLoading(false);
    }
  };

  // Initial auth check
  useEffect(() => {
    refreshAuth();
  }, []);

  const value = {
    isAuthenticated,
    email,
    screenName,
    isLoading,
    refreshAuth,
    error,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
